@media only screen and (min-width: 768px)
{
	.side-bar {
		&__nav {
			margin-top: 40px;
		}
		&__nav-links {
			// margin-top: 40px;

			&--sub {
				margin-top: 0;
			}
		}
		&__footer-call-back {

			text-align: center;
		}
		&__footer-phone {
			order: 1;
			margin-bottom: 0;
			margin-bottom: 15px;

			a {
				display: block;
				margin-left: 15px;
			}
		}
	}
}
@media only screen and (min-width: 1024px) {
	.side-bar {

		&__footer-phone {
			display: none;
		}
		&__footer-call-back {
			display: block;
		}
		&__footer-social {
			text-align: center;
		}
	}
}
@media only screen and (min-width: 1285px) {
	.side-bar {
		min-width: 325px;
		width: 325px;

		// position: static;
		top: 0;
		left: 0;
		opacity: 1;
		visibility: visible;

		&__logo {
			display: block;
		}
		&__content {
			height: 100%;
			display: block;
			padding-top: 35px;
			padding-left: 35px;
		}
		&__nav-links {
			&--sub.active {
				left: 0;
			}
		}
		&__nav-link {
			// font-size: 22px;

			&:before {
				height: 20px;
			}
		}
		&__footer {
			position: absolute;
			bottom: 35px;
			left: 0;
			right: 0;
		}
		&__footer-call-back {
			padding: 0;
		}
		&__footer-social,
		&__footer-copyright {
			padding: 0 35px;

			text-align: left;
		}
		&__footer-social {
			padding-bottom: 15px;
		}
		&__footer-copyright {
			padding-top: 15px;
		}
	}
}

@media only screen and (min-width: 1545px) {
	.side-bar {
		min-width: 375px;
		width: 375;

		&__content {
			padding-top: 70px;
			padding-left: 40px;
		}
		&__nav {
			margin-top: 110px;
		}
		&__nav-link {
			padding-bottom: 24px;
			a {
				font-size: 23px;
			}
			&:before {
				height: 24px;

				top: -2px;
			}
			&--back {
				font-size: 18px;
			}
			img {
				height: 13px;
				margin-right: 10px;
			}
		}
		&__nav-links--sub > &__nav-link {
			padding-bottom: 15px;

			a {
				font-size: 15px;
			}
			&:before {
				top: -1px;
			}
		}
	}
}
