@media only screen and (min-width: 1024px)
{
	.article {
		p {
			width: 830px;
			margin: 0 auto;
			margin-bottom: 70px;
		}
		blockquote {
			margin-bottom: 70px;

			p {
				width: 700px;

				&:before {
					width: 60px;
					height: 50px;

					top: 8px;
					right: 100%;

					margin-right: 50px;
				}
			}
		}
		&--align-left {
			margin-top: 60px;
			p {
				margin: 0;
				margin-bottom: 40px;
			}
		}
		&--with-header {
			margin-top: 0;
		}
	}
}
@media only screen and (min-width: 1545px)
{
	.article {
		width: 1170px;
		padding-left: 100px;

		&--with-header {
			padding-left: 0;
		}
	}
}
@media only screen and (min-width: 1920px)
{
	.article {
		width: 1170px;
		margin: 0 auto;
		margin-top: 100px;
		padding-left: 0;

		&--align-left {
			margin-top: 60px;
		}

	}
}
