.news-card {
	// display: flex;
	padding: 30px;
	margin-bottom: 20px;

	background-color: #fff;
	border: 1px solid #eff0f4;
	border-radius: 9px;

	text-align: center;
	transition: all .5s ease-in-out;

	&__img {
		width: 150px;
		height: 150px;

		margin: 0 auto;

		border-radius: 9px;
	}
	&__info-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}
	&__info-box > .button {
		width: 190px;
	}
	&__info {
		display: flex;
		align-items: center;
		margin-top: 10px;

		color: #c5c5c5;
		font-size: 13px;
		// font-family: 'Geometria-Medium';

		span {
			margin-right: 15px;
		}
	}
	&__info-view {
		display: flex;
		align-items: center;

		img {
			height: 20px;
			margin-right: 10px;
		}
	}
	&__title {
		// height: 50px;
		width: 190px;
		height: 50px;

		position: relative;
		overflow: hidden;

		margin: 20px 0;

		font-size: 18px;
		text-transform: uppercase;
		line-height: 1.4;

		// &--active {
		// 	&:after {
		// 		content: "...";
		//
		// 		position: absolute;
		// 		bottom: 0;
		// 		right: 0;
		//
		// 		font-size: 21px;
		// 	}
		// }
	}
	// &__desc {
	// 	color: #c5c5c5;
	// 	font-size: 13px;
	// 	text-transform:  uppercase;
	// }
	&:hover {
		box-shadow: 0 50px 92px rgba(0,0,0,0.19);
	}
}
