@media only screen and (min-width: 1024px)
{
	.top-banner {
		background-position: 25% 50%;
		&__desc {
			// width: 960px;

			padding-left: 40px;

			h3 {
				color: #c5c5c5;
			}
		}
	}
}

@media only screen and (min-width: 1545px) {
	.top-banner {
		&__desc {
			width: 1170px;

			padding-left: 100px;
		}
	}
}

@media only screen and (min-width: 1920px) {
	.top-banner {
		&__desc {
			margin: 0 auto;
		}
	}
}
