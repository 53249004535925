@media only screen and (min-width: 1024px)
{
	.form {
		width: 770px;
		margin: 0 auto;
		margin-bottom: 70px;
		padding: 40px 70px;

		&--vertical {
			width: auto;

			padding: 25px;
		}
		&--vertical &__desc {
			margin-bottom: 15px;

			&--bottom {
				margin-bottom: 0;
			}
		}
	}
}

@media only screen and (min-width: 1545px)
{
	.form {
		&__desc {

			&--bottom {
				img {
					margin-right: 0;
				}
			}
		}
	}
}
