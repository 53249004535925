.slide {

	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;

	border-top: 2px solid #e3e9ee;

	&__img {
		height: 250px;

		order: 2;
		margin: 30px auto;
		// height: 300px;
	}
	&__title {
		padding-top: 30px;
		// margin-bottom: 50px;
		margin-top: 0;
		margin-bottom: 40px;

		font-size: 21px;
		text-transform: uppercase;
	}
	&__desc {

	}
	&__social {
		margin-top: 50px;
		a {
			margin-right: 20px;
		}
		svg {
			width: 25px;
			height: 25px;

			path,
			polygon {
				fill: $main-color;
				transition: all .3s ease-in-out;
			}
		}
		a:hover svg path,
		a:hover svg polygon {
			fill: #3d5561 !important;
		}
	}
	&__contacts {
		display: flex;
		flex-wrap: wrap;
	}
	&__contact {
		// width: 50%;
		width: 100%;

		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		font-size: 12px;
		font-family: 'Geometria-Medium';
		text-transform: uppercase;

		label {
			color: #26373f;
		}
		span {
			color: #b1c7d1;
		}
	}
	&--contacts-page {
		border: 0;

		margin-top: 20px;
	}
	&--services-page {
		border: 0;

		margin-top: 50px;
	}
	&--services-page &__img {
		order: 0;
	}
}
