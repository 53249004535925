@media only screen and (min-width: 768px)
{

}
@media only screen and (min-width: 1024px)
{
	.pop-up {
		&__content {
			width: 600px;
			margin: 0 auto;
			margin-top: 4%;

			&--form {
				width: 400px;
			}
		}
		&--active &__content {
			margin-top: 4%;
		}
	}
}
