@media only screen and (min-width: 768px)
{
	.button {
		&--only-tablet {
			display: flex;
			margin-left: auto;
			margin-right: 10px;
		}
		&--call-back {
			display: none;
		}
	}
}
@media only screen and (min-width: 1920px) {
	.button {
		&--only-tablet {
			margin-right: 0;
		}
	}
}
