.nav-icon {
	width: 30px;
	height: 30px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	z-index: 100;
	overflow: hidden;

	margin-top: 3px;

	& span {
		display: block;
		position: absolute;
		height: 4px;
		width: 50%;
		background-color: $main-color;
		opacity: 1;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(even) {
			left: 50%;
		}
		&:nth-child(odd) {
			left: 0px;
		}
		&:nth-child(1),
		&:nth-child(2) {
			top: 0px;
		}
		&:nth-child(3),
		&:nth-child(4) {
			top: 12px;
		}
		&:nth-child(5),
		&:nth-child(6) {
			top: 24px;
		}
	}
	&.open span {
		background-color: #222;

		&:nth-child(1),
		&:nth-child(6) {
			transform: rotate(45deg);
		}
		&:nth-child(2),
		&:nth-child(5) {
			transform: rotate(-45deg);
		}
		&:nth-child(1) {
			left: 3px;
			top: 5px;
		}
		&:nth-child(2) {
			left: calc(50% - 2px);
			top: 5px;
		}
		&:nth-child(3) {
			left: -50%;
			opacity: 0;
		}
		&:nth-child(4) {
			left: 100%;
			opacity: 0;
		}
		&:nth-child(5) {
			left: 3px;
			top: 15px;
		}
		&:nth-child(6) {
			left: calc(50% - 2px);
			top: 15px;
		}
	}
}
