.button {
	position: relative;

	// display: flex;
	// align-items: center;
	// justify-content: center;
	display: block;
	padding: 10px 25px;

	color: #9ca4a9;
	font-size: 12px;
	text-align: center;

	border-radius: 5px;
	border: 0;
	cursor: pointer;
	background-color: $main-color;
	transition: all .3s ease-in-out;

	&:hover {
		opacity: 0.8;
	}

	&--rounded {
		border-radius: 20px;
	}
	&--small {
		padding: 5px 10px;
	}

	&--with-icon {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			margin-right: 15px;
		}
	}
	&--only-tablet {
		display: none;

		width: 200px;
	}
	&--call-back {
		margin-top: 10px;
		// margin-bottom: 10px;
		background-color: #33515f;
		opacity: 1;
	}
	&--call-back.active {
		opacity: 0;
	}
	&--service-card {
		margin: 0 25px;
	}
	&--grey {
		color: #fff;
		background-color: #99a1a5;
	}
	&--form {
		width: 100%;
		font-family: 'Geometria-Medium';
	}
}
.buttons {
	display: flex;
	justify-content: space-between;
}
