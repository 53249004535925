.pop-up {
	width: 100%;
	height: 100%;

	position: fixed;
	bottom: -100%;
	left: 0;
	z-index: 1000;
	overflow-y: scroll;
	transform: translateZ(0);

	background-color: rgba(38, 55, 63, 0.9);

	visibility: hidden;
	opacity: 0;
	transition: all .5s ease-in-out;

	&__opener {
		cursor: pointer;
	}
	&__closer {
		transition: all 350ms ease-in-out;

		&--cross {
			cursor: pointer;
			width: 25px;
			height: 25px;

			position: fixed;
			top: 20px;
			right: 20px;
		}
		&:hover {
			opacity: 0.8;
		}
	}
	&__content {
		position: relative;
		bottom: -100%;
		margin: 0 25px;

		transition: all 1s ease-in-out;

		&--msg {
			padding: 20px;

			color: $main-color;

			border-radius: 9px;
			border: 1px solid #e3e9ee;
			background-color: #fff;
		}
	}
	&--active {
		bottom: 0;
		visibility: visible;
		opacity: 1;
	}
	&--active &__content {
		bottom: 0;
		margin-top: 64px;
	}
	&__image {
		width: 100%;
		margin-bottom: 10px;
	}
}
