@media only screen and (min-width: 768px)
{
	.slide {
		height: 450px;
		flex-direction: row;

		&--contacts-page {
			height: auto;

			align-items: flex-start;
			margin-top: 100px;
		}
		&--services-page {
			height: auto;

			align-items: flex-start;
		}
		&--services-page &__desc {
			width: 50%;
		}
		&__img {
			height: 300px;
			margin: 0 auto;
			margin-right: 75px;
			order: 0;
		}
		&__contacts--col &__contact {
			flex-direction: column;
		}
	}
}
@media only screen and (min-width: 1024px)
{
	.slide {
		&--contacts-page {
			width: 770px;
			margin: 0 auto;
			margin-top: 100px;
		}
		&--contacts-page &__img {
			height: 320px;
			width: 75%;

			margin: 0;
		}
		&--contacts-page &__title {
			margin-bottom: 30px;
		}
		&__title {
			padding-top: 0;
			margin-bottom: 50px;
		}
		&__contact {
			width: 50%;
		}
		&__contacts--col &__contact {
			width: 100%;
		}
	}
}
@media only screen and (min-width: 1545px) {
	.slide {

		&__img {
			margin-right: 150px;
		}
		&--services-page &__title {
			margin-bottom: 100px;
		}
		&--services-page &__img {
			max-width: 300px;
			margin-right: 0;
			margin-top: 60px;
		}
		&--services-page .form{
			min-width: 400px;
			margin-left: 70px;
		}
	}
}
