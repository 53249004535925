.testimonial {
	display: flex;
	flex-direction: column;

	&__author {
		height: 180px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 0;
		margin-bottom: 20px;

		// border-bottom: 2px solid #e3e9ee;
	}
	&__author-avatar {
		width: 100px;
		height: 100px;
		min-width: 100px;
		min-height: 100px;
		max-width: 100px;
		max-height: 100px;
		margin-bottom: 20px;

		border-radius: 50%;
		border: 4px solid #ccd9e3;

		box-shadow: 0px 6px 17px 5px #becad4;
	}
	&__author-desc {
		font-size: 18px;
		font-family: 'Geometria-Medium';
		white-space: nowrap;

		text-align: center;
	}
	&__author-name {
		padding-bottom: 15px;
	}
	&__text {
		position: relative;

		padding: 20px 0;

		p {
			position: relative;
			z-index: 2;

			font-size: 18px;
			color: #090909;
			line-height: 1.6;
			font-family: 'Geometria-LightItalic';
		}
		&:after {
			content: '';

			width: 98px;
			height: 86px;

			position: absolute;
			bottom: 10px;
			right: 0;

			z-index: 1;

			background-image: url(../img/icon/quotes.svg);
			background-size: cover;
		}
	}
}

.slick-prev,
.slick-next {
	width: 17px;
	height: 23px;

	position: absolute;
	top: 65px;

	border: 0;

	cursor: pointer;

	z-index: 80;
}
.slick-prev {
	left: 35px;
	transform: rotate(180deg);
}
.slick-next {
	right: 35px;
}
