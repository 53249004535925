.side-bar {
	// min-width: 325px;
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	right: -100%;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	overflow-y: auto;

	padding-top: 60px;

	background-color: $main-color;

	-webkit-transform: translateZ(0);
	transition: all 300ms ease-in-out;

	&--open {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	&__logo {
		display: none;
	}
	&__content {
		height: 100%;
		min-height: 480px;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;

		padding: 20px;

		background-color: $main-color;

		@media (min-width: 768px) {
			min-height: 430px;
		}
		
		@media (min-width: 1024px) {
			min-height: 375px;
		}
	}
	&__footer {
		position: absolute;
		bottom: 15px;
		margin-top: 30px;
		width: 100%;
	}
	&__footer-call-back {
		padding: 0 15px;
		padding-bottom: 15px;
		border-bottom: 2px solid #44525b;

		text-align: center;

		a {
			margin-right: 15px;

			&:last-of-type {
				margin-right: 0;
			}
		}
		svg {
			width: 25px;
			height: 25px;

			path,
			polygon {
				transition: all .3s ease-in-out;
			}
		}
		a:hover svg path,
		a:hover svg polygon {
			fill: #ffffff !important;
		}
	}
	&__footer-phone {
		margin-bottom: 15px;

		span {
			color: #c5c5c5;
			font-size: 14px;

			img {
				margin-right: 10px;
			}
		}
		a {
			display: block;
			margin-top: 10px;
			white-space: nowrap;
			color: #fff;
			font-family: "Geometria-Medium";
		}
	}
	&__footer-copyright {
		padding: 0 15px;

		padding-top: 15px;

		color: #8b99a2;
		font-size: 11px;
		font-family: 'Geometria-Medium';
		text-transform: uppercase;
		text-align: center;

		a {
			color: #8b99a2;
			transition: all .3s ease-in-out;
		}
		a:hover {
			color: #fff;
		}
	}
	&__nav {
		position: relative;
	}
	&__nav-links, menu-item {
		position: relative;
		left: 0;
		margin-top: 15px;

		list-style: none;
		transition: all .7s ease;

		// text-align: center;

		&--hide {
			left: 100%;
			opacity: 0;
			visibility: hidden;
		}
		&--sub {
			position: absolute;
			top: 0;
			left: -100%;
			opacity: 0;
			visibility: hidden;

			margin-top: 0;
		}
		&--sub.active {
			left: -40px;
			opacity: 1;
			visibility: visible;
		}
	}
	&__nav-links--sub > &__nav-link {
		padding-bottom: 10px;

		a {
			font-size: 13px;
			line-height: 1.5;
		}
	}
	&__nav-link, .menu-item {

		position: relative;

		padding-bottom: 20px;

		transition: all .3s ease-in-out;

		a {
			color: #8b99a2;
			font-size: 20px;
			// font-size: 22px;
			font-family: 'Geometria-Bold';
			line-height: 0.8;
			text-transform: uppercase;
			cursor: pointer;
			white-space: nowrap;

			transition: all .3s ease-in-out;
		}
		img {
			height: 10px;
			margin-right: 5px;
		}
		&:before {
			content: '';

			position: absolute;
			top: -1px;
			left: -50px;

			width: 7px;
			// height: 19px;
			height: 22px;

			background-color: #fff;
			opacity: 0;
			transition: all .3s ease-in-out;
		}
		&:hover {
			padding-left: 20px;

			a {
				color: #fff;
			}
			&:before {
				left: 0;
				opacity: 1;
			}
		}
		&--back {
			color: #fff;
			text-transform: none;
			font-size: 14px;
			cursor: pointer;

			img {
				transition: all 300ms ease-in-out;
			}
			&:before {
				display: none;
			}
			&:hover {
				padding-left: 0;

				img {
					padding-right: 5px;
				}
			}
		}
		&.active, &.current-menu-item {
			padding-left: 20px;

			a {
				color: #fff;
			}
			&:before {
				left: 0;
				opacity: 1;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
