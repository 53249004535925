@font-face {
	font-family: "Geometria";
	src: url("../fonts/Geometria.otf");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "Geometria-Italic";
	src: url("../fonts/Geometria-Italic.otf");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "Geometria-LightItalic";
	src: url("../fonts/Geometria-LightItalic.otf");
	font-style: normal;
	font-weight: normal;
}

// Medium

@font-face {
	font-family: "Geometria-Medium";
	src: url("../fonts/Geometria-Medium.otf");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "Geometria-MediumItalic";
	src: url("../fonts/Geometria-MediumItalic.otf");
	font-style: normal;
	font-weight: normal;
}

// Bold

@font-face {
	font-family: "Geometria-Bold";
	src: url("../fonts/Geometria-Bold.otf");
	font-style: normal;
	font-weight: normal;
}
