$main-color: #26373f;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: 0;
}

a {
	text-decoration: none;
}

body {
	font-family: "Geometria";

	background-color: #f5f9fc;
}
.body {
	&--no-scroll {
		overflow: hidden;
	}
	&--scroll-smooth {
		margin-right: 15px;
	}
}

.container {
	height: 100%;

	overflow: hidden;
	// font-size: 0;
}
.content {
	padding-top: 60px;
}

// temp
.about-services-wrap {
	border-top: 2px solid #e3e9ee;
	border-bottom: 2px solid #e3e9ee;

	margin-left: 0;

	.grid__cell {
		padding-left: 0;
	}
}
.about-services {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 40px;
	&__img {

		width: 60px;
		height: 60px;
		margin: 0 auto;
	}
	&__desc {
		margin-top: 15px;

		color: #26373f;
		font-size: 17px;
		font-family: 'Geometria-Medium';
		text-align: center;
		line-height: 1.5;
		text-transform: uppercase;
	}
}
.card-slider {
	&__btn {
		width: 17px;
		z-index: 80;

		position: absolute;
		top: 50%;

		&--next {
			transform: rotate(90deg);
			left: 100%;
		}
		&--prev {
			left: 0;
			transform: rotate(-90deg);
		}
	}
}

.gallery {

	&__card {
		width: 100%;
		height: 175px;
		margin-bottom: 20px;
		position: relative;
		overflow: hidden;
		background-color: #e4e4e4;
		border-radius: 3px;
		cursor: pointer;
		background-size: cover;
		background-position: center center;
	}
	&__img {
		&--landscape {
			width: auto;
			height: 100%;
		}
		&--portrait {
			width: 100%;
			height: auto;
		}
	}
}

.section {
	padding: 0 20px;

	&--services {
		padding-bottom: 40px;
	}
	&--about {
		margin: 60px 0;
	}
	p.msg, &>p {
		margin: 25px auto;

		font-size: 18px;
		text-align: center;
		line-height: 1.4;
	}
}
.section-header {
	// margin: 0 20px;
	// margin-right: 20px;
	padding: 30px 0;

	border-top: 2px solid #e3e9ee;

	font-size: 21px;
	text-transform: uppercase;

	&--no-border {
		border: 0;
	}
	&--with-border {
		position: relative;

		// padding: 50px 0;

		&:before {
			content: '';

			width: 100px;
			height: 2px;

			position: absolute;
			bottom: 25px;
			left: 0;

			background-color: $main-color;
		}
	}
}

.error {
	background-color: red;
}

@import "slick.css";

// common.blocks

@import "common.blocks/nav-icon.scss";
@import "common.blocks/button.scss";
@import "common.blocks/form.scss";
@import "common.blocks/input.scss";
@import "common.blocks/pop-up.scss";
@import "common.blocks/fonts.scss";
@import "common.blocks/testimonial.scss";
@import "common.blocks/top-header.scss";
@import "common.blocks/side-bar.scss";
@import "common.blocks/service-card.scss";
@import "common.blocks/about-card.scss";
@import "common.blocks/top-banner.scss";
@import "common.blocks/blog-header.scss";
@import "common.blocks/slide.scss";
@import "common.blocks/news-card.scss";
@import "common.blocks/article.scss";

// main tablet media
@media only screen and (min-width: 768px)
{
	.section {
		&--msg {
			&>p {
				margin: 50px auto;
			}
		}
	}
}

@media only screen and (min-width: 1024px)
{
	.about-services {
		flex-direction: row;
		align-items: center;
		margin: 45px 0;

		&__img {
			width: 48px;
			height: 48px;
			margin: 0;
			margin-right: 20px;
		}
		&__desc {
			margin-top: 0;
			text-align: left;
		}
	}
	.section {
		padding: 0 40px;

		&--msg {
			&>p {
				width: 770px;
			}
		}
	}
	.section-header {
		// margin: 0 40px;
	}
}

// main desktop media
@media only screen and (min-width: 1285px)
{
	.container {
		margin-left: 325px;
	}
	// .section-header {
	// 	margin-left: 40px;
	// }
}

@media only screen and (min-width: 1545px) {
	.container {
		margin-left: 375px;
	}
	.grid {
		margin-left: -40px;

		&__cell {
			padding-left: 40px;
		}
	}
	.section-header {
		// width: 1060px;

		padding-top: 70px;
		// margin-left: 100px;
		// padding-right: 10px;
	}
	.section {
		width: 1170px;
		padding-left: 100px;
		padding-right: 10px;

		&--services {
			padding-bottom: 75px;
		}
	}
	.about-services-wrap {
		margin-left: 0;
	}
}
@media only screen and (min-width: 1920px) {
	.container {
		margin-left: 0;
	}
	.section {
		margin: 0 auto;
		padding-right: 0;
	}
	.section-header {
		// width: 1170px;

		margin: 0 auto;
		// padding-left: 100px;

		&--with-border {
			&:before {
				// left: 100px;
			}
		}
	}
}
// desktop.blocks
@import "desktop.blocks/top-header.scss";
@import "desktop.blocks/nav-icon.scss";
@import "desktop.blocks/button.scss";
@import "desktop.blocks/form.scss";
@import "desktop.blocks/pop-up.scss";
@import "desktop.blocks/side-bar.scss";
@import "desktop.blocks/top-banner.scss";
@import "desktop.blocks/blog-header.scss";
@import "desktop.blocks/about-card.scss";
@import "desktop.blocks/news-card.scss";
@import "desktop.blocks/slide.scss";
@import "desktop.blocks/testimonial.scss";
@import "desktop.blocks/article.scss";
