.article {

	p {
		margin: 0 20px;
		margin-bottom: 40px;

		color: #090909;
		font-size: 18px;
		line-height: 1.7;
	}
	b {
		font-family: 'Geometria-Medium';
	}
	a {
		color: #74838d;
		font-family: 'Geometria-Medium';
		border-bottom: 1px solid #74838d;

		transition: all .3s ease-in-out;

		&:hover {
			opacity: 0.8;
		}
	}
	blockquote {

		padding: 30px 0;
		margin-bottom: 40px;

		background: linear-gradient(to right, #dbebf3 0%, rgba(219, 235, 243, 0.53) 50%, rgba(219, 235, 243, 0.5) 40%, rgba(219, 235, 243, 0) 95%);

		p {
			position: relative;
			z-index: 2;

			margin-bottom: 0;

			color: #26373f;
			font-family: 'Geometria-MediumItalic';

			&:before {
				content: '';

				width: 98px;
				height: 86px;

				position: absolute;
				bottom: 0;
				right: 0;
				z-index: -1;

				background-image: url(../img/icon/quotes.svg);
				background-size: cover;
			}
		}
	}
	&--align-left {
		margin-top: 20px;

		p {
			margin: 0;
			margin-bottom: 40px;
		}
	}
}

.pagination{
	margin-bottom: 50px;
	.nav-links{
		text-align: center;

		a, a:visited, a:hover{
			color: #99a1a5
		}
	}
}
