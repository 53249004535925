@media only screen and (min-width: 768px)
{
	.blog-header {
		&__title {
			font-size: 31px;
		}
	}
}
@media only screen and (min-width: 1024px)
{
	.blog-header {
		// max-width: 1100px;
		// margin: 40px;
		margin: 0;
		margin-top: 40px;
		margin-bottom: 60px;
		padding: 50px 30px;

		&:before {
			background: linear-gradient(to right, rgba(38, 55, 63, 1) 0%,rgba(38,55,63,0.75) 41%,rgba(38,55,63,0.43) 59%,rgba(38,55,63,0) 95%);
		}

		&__bottom {
			width: 50%;
		}
	}
}
@media only screen and (min-width: 1545px)
{
	.blog-header {
		width: 1060px;

		// margin-left: 100px;
		// margin-right: 10px;
	}
}
@media only screen and (min-width: 1920px)
{
	.blog-header {
		width: 100%;

		margin: 0;
		margin-top: 50px;
		margin-left: 0;
	}
}
