.top-banner {
	height: 436px;

	position: relative;

	display: flex;
	align-items:center;

	overflow: hidden;

	background-color: #f5f9fc;
	background-size: cover;
	background-position: 50% 50%;

	&:before {
		content: '';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		background: linear-gradient(to right, #ffffff 0%, rgba(245, 249, 252, 0.95) 41%, rgba(245, 249, 252, 0.78) 59%, rgba(245, 249, 252, 0.3) 95%);
	}

	&__desc {
		position: relative;
		z-index: 2;
		padding: 0 20px;

		h1 {
			margin: 0;
			margin-bottom: 40px;
			max-width: 600px;
			color: #162830;
			font-size: 28px;
			line-height: 1.2;
			text-transform: uppercase;
		}
		h3 {
			max-width: 600px;
			color: #909090;
			font-size: 14px;
			line-height: 1.6;
			text-transform: uppercase;
		}
	}
}
