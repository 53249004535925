@media only screen and (min-width: 1024px)
{
	.testimonial {
		flex-direction: row;
		margin-bottom: 60px;

		&__author {
			flex-direction: row;
			padding: 0 40px;
			margin-bottom: 0;

			border-bottom: 0;
			border-right: 2px solid #e3e9ee;
		}
		&__author-avatar {
			margin-bottom: 0;
			margin-right: 40px;
		}
		&__author-desc {
			text-align: left;
		}
		&__text {
			padding: 0;
			padding-left: 40px;

			&:after {
				bottom: -40px;
			}
		}
	}
	.slick-prev,
	.slick-next {
		top: 146px;
	}
	.slick-prev {
		left: 180px;
	}
	.slick-next {
		left: 230px;
	}
}
@media only screen and (min-width: 1545px) {
	.testimonial {
		padding-top: 30px;
	}
	.slick-prev,
	.slick-next {
		top: 170px;
	}
}
