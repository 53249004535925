@media only screen and (min-width: 1024px)
{
	.news-card {
		display: flex;

		text-align: left;

		&__img {
			margin: 0;
			margin-right: 40px;
		}
		&__info-box {
			align-items: flex-start;
		}
	}
}

@media only screen and (min-width: 1545px) {
	.news-card {
		margin-bottom: 40px;
	}
}
