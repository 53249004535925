@media only screen and (min-width: 768px)
{
	.about-card {
		height: 200px;

		flex-direction: row;
		// align-items: center;

		&__img {
			margin-right: 40px;
			margin-bottom: 0;
		}
		&__text {
			text-align: left;
		}
	}
}

@media only screen and (min-width: 1545px) {
	.about-card {
		margin-bottom: 40px;
	}
}
