.blog-header {
	height: 365px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 20px 0;
	padding: 30px 20px;

	position: relative;
	overflow: hidden;

	font-family: 'Geometria-Medium';

	background-color: #26373f;

	&:before {
		content: '';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		background: linear-gradient(to right, #26373f 0%, rgba(38, 55, 63, 0.81) 41%, rgba(38, 55, 63, 0.78) 59%, rgba(38, 55, 63, 0.3) 95%);
	}
	&:hover &__bg-img {
		transform: scale3d(1.1, 1.1, 1.1);
	}
	&__bg-img {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		background-size: cover;
		background-position: 50% 50%;
		transform: scale3d(1, 1, 1);
		transition: all 1s ease-in-out;
	}
	&__info {
		z-index: 3;

		color: #ffffff;
		font-size: 13px;
	}
	&__info-date {
		margin-right: 15px;
	}
	&__info-view {

		img {
			height: 15px;

			margin-bottom: -2px;
			margin-right: 10px;
		}
	}
	&__bottom {
		z-index: 3;

		color: #fff;
	}
	&__title {
		display: block;
		margin-bottom: 25px;

		color: #fff;
		font-size: 25px;
	}
	&__desc {
		font-size: 14px;
		line-height: 1.5;
		text-transform: uppercase;
	}
}
