.about-card {
	// height: 200px;

	position: relative;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px 25px;
	margin-bottom: 20px;

	border: 1px solid #eff0f4;
	border-radius: 9px;

	cursor: pointer;
	transform: translateZ(0);
	transition: all .5s ease-in-out;

	&:before {
		content: '';

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		background-color: #fff;
		border-radius: 9px;

		transition: all .5s ease-in-out;
	}
	&:hover:before {
		background-color: rgba(38, 55, 63, 0.8);
	}
	&:hover &__text {
		color: #fff;
	}
	&:hover &__bg-img {
		transform: scale(1.1);
		opacity: 1;
	}
	&:hover &__img svg path,
	&:hover &__img svg polygon {
		fill: #ffffff !important;
	}
	&__bg-img {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		background-size: cover;
		background-position: 50% 50%;
		border-radius: 11px;

		opacity: 1;

		transition: all .5s ease-in-out;
		transform:scale(1);
	}
	&__img {
		width: 90px;
		height: 90px;

		margin: 0 auto;
		margin-bottom: 40px;
		z-index: 3;

		svg {
			width: 90px;
			height: 90px;
		}
		path,
		polygon {
			transition: all .5s ease-in-out;
		}
	}
	&__text {
		text-align: center;
		color: #26373f;
		z-index: 3;

		transition: all .5s ease-in-out;
	}
	&__title {
		margin-bottom: 20px;
		font-size: 17px;
		font-family: "Geometria-Medium";
		text-transform: uppercase;
	}
	&__desc {
		font-size: 14px;
		line-height: 1.4;
	}
}
