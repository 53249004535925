.top-header {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 60px;
	padding: 10px;

	z-index: 100;

	background-color: #fff;
	-webkit-transform: translateZ(0);

	&__content {
		-js-display: flex;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__logo {
		width: 40px;
		height: 40px;

		background-color: #000;
	}
	&__call-back {
		display: none;
	}
}
