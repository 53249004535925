.input {
	position: relative;

	display: flex;
	align-items: center;
	margin-bottom: 30px;

	border-bottom: 1px solid #bdcfd7;
	transition: all .3s ease-in-out;

	&__icon {
		width: 15px;

		margin-right: 10px;

		svg {
			width: 15px;
			height: 15px;
		}
		path {
			transition: all .3s ease-in-out;
		}
	}
	&__title {
		position: absolute;
		top: 10px;
		left: 25px;

		color: #b1c7d1;
		font-size: 12px;
		font-family: 'Geometria-Medium';

		transition: all .3s ease-in-out;
		pointer-events: none;
	}
	&__input-field {
		width: 100%;

		padding: 8px 0;
		order: 2;

		border: 0;
		outline: 0;


		color: #44525b;
		font-size: 12px;
		font-family: 'Geometria-Medium';
		&.error{
			background-color: #fff;
		}
	}
	&__input-field.input__input-field--invalid-state ~ &__line, .error ~ &__line {
		width: 100%;
		background-color: #E74C3C;
	}
	&__input-field.input__input-field--valid-state ~ &__line, .valid ~ &__line {
		width: 100%;
		background-color: #57b780;
	}
	&__line {
		width: 0;
		height: 1px;

		position: absolute;
		bottom: -1px;

		background-color: #44525b;

		transition: all .3s ease-in-out;
	}
	&__input-field.input__input-field--active ~ &__title,
	&__input-field:focus ~ &__title {
		top: -10px;

		color: $main-color;
		font-size: 10px;
		background-color: #fff;
	}
	&__input-field.input__input-field--active ~ &__line,
	&__input-field:focus ~ &__line {
		width: 100%;
	}
	&__input-field.input__input-field--active ~ &__icon svg path,
	&__input-field:focus ~ &__icon svg path {
		fill: $main-color !important;
	}
	&--textarea {
		align-items: flex-start;

		textarea {
			width: 100%;
			height: 26px;

			line-height: 1.4;

			border: 0;
			outline: 0;
			resize: none;
			transition: all .3s ease-in-out;
		}
	}
	&--textarea &__title {
		top: 0;
	}
	&--textarea &__input-field {
		padding: 0;
	}
	&--textarea &__input-field.input__input-field--active ~ &__title,
	&--textarea &__input-field:focus ~ &__title {
		top: -15px;
	}
	&--textarea &__input-field.input__input-field--active,
	&--textarea &__input-field:focus {
		height: 80px;
	}
}
