.service-card {
	// height: 425px;
	// margin: 20px;
	margin-top: 0;
	margin-bottom: 20px;
	padding: 20px;

	text-align: center;

	background-color: #fff;
	border: 1px solid #eff0f4;
	border-radius: 9px;

	transition: all .5s ease-in-out;

	&__img {
		display: block;
		margin: 0 auto;

		border-radius: 50%;
	}
	&__title {
		min-height: 40px;
		max-height: 40px;
		margin: 15px 0;

		color: #162830;
		font-size: 17px;
		font-family: 'Geometria-Medium';
	}
	&__desc {
		min-height: 125px;
		max-height: 125px;

		margin: 0 20px;
		margin-bottom: 25px;

		color: #c5c5c5;
		font-size: 15px;
		line-height: 1.5;
		font-family: 'Geometria-Medium';
	}
	&:hover {
		box-shadow: 0 50px 92px rgba(0,0,0,0.19);
	}
}
