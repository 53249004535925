@media only screen and (min-width: 768px)
{

}
@media only screen and (min-width: 1024px)
{
	.top-header {
		&__call-back {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: auto;

			img {
				margin-right: 15px;
			}
			span {
				color: #c5c5c5;
				font-size: 18px;
				margin-right: 20px;
			}
			&-phone {
				color: #162830;
				font-size: 18px;
				font-family: "Geometria-Medium";
				text-align: center;
			}
		}
	}
}

@media only screen and (min-width: 1285px) {
	.top-header {
		&__content {
			margin-left: 355px;
		}
		&__call-back {
			margin-left: 0;
		}
		&__logo {
			display: none;
		}
	}
}

@media only screen and (min-width: 1545px) {
	.top-header {
		&__content {
			width: 1170px;

			padding-left: 100px;
			margin-left: 365px;
		}
	}
}
@media only screen and (min-width: 1920px) {
	.top-header {
		&__content {
			margin: 0 auto;
		}
	}
}
