.form {
	margin-bottom: 70px;
	padding: 20px;

	background-color: #fff;
	border: 1px solid #eff0f4;
	border-radius: 9px;

	text-align: center;

	&__title {
		margin-bottom: 15px;

		color: #162830;
		font-size: 21px;
	}
	&__desc {
		margin-bottom: 60px;

		color: #b1c7d1;
		font-family: 'Geometria-Medium';
		font-size: 12px;
		line-height: 1.8;

		&--bottom {
			margin-top: 20px;
			margin-bottom: 0;

			img {
				width: 14px;

				margin-bottom: -3px;
				margin-right: 10px;
			}
		}
	}
	.grid {
		margin-left: -40px;
	}
	.grid__cell {
		padding-left: 40px;
	}
}
